import 'flickity/dist/flickity.pkgd.js';
import Flickity from 'flickity';

let dynamicStyles = null;

Flickity.prototype._createResizeClass = function() {
  this.element.classList.add('flickity-resize');
};

Flickity.createMethods.push('_createResizeClass');

var resize = Flickity.prototype.resize;
Flickity.prototype.resize = function() {
  this.element.classList.remove('flickity-resize');
  resize.call( this );
  this.element.classList.add('flickity-resize');
};

export default {
  init() {
    // JavaScript to be fired on the home page

    Flickity.defaults.dragThreshold = 3;
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
    homeMap();
    homecardSectionGridSlider();
    testimonialsSlider();
    scrollOnID();

    var observer = new IntersectionObserver(function(entries) {
      if (entries[0].isIntersecting === true) {
        entries[0].target.classList.add('on-show');
      } else {
        entries[0].target.classList.remove('on-show');
      }
    }, {threshold: [1] });

    for (let index = 0; index < document.querySelectorAll('.home-solutions-section .cards-section .card-item').length; index++) {
      observer.observe(document.querySelectorAll('.home-solutions-section .cards-section .card-item')[index]);
    }
  },
};

$(window).on('resize', function () {
  $('.home-testimonials-section .testimonials-wrp, .home-statistics-section .cards-wrp').flickity('resize');
});

$(window).on('load', function () {
  $('.home-testimonials-section .testimonials-wrp, .home-statistics-section .cards-wrp').flickity('resize');
});

function homecardSectionGridSlider() {
  $('.home-statistics-section .cards-wrp').flickity({
    pageDots: true,
    prevNextButtons: false,
    wrapAround: true,
    watchCSS: true,
  });
}

function testimonialsSlider() {
  $('.home-testimonials-section .testimonials-wrp').flickity({
    pageDots: true,
    prevNextButtons: true,
    wrapAround: true,
    cellAlign: 'center',
    adaptiveHeight: true,
  });

  $('.home-testimonials-section .testimonials-wrp').find('.flickity-button').appendTo($('.home-testimonials-section .testimonials-wrp-outer').find('.flickity-wrp'));
}


// Inject Keyframes Dynamically
function addAnimation(body) {
  if (!dynamicStyles) {
    dynamicStyles = document.createElement('style');
    dynamicStyles.type = 'text/css';
    document.head.appendChild(dynamicStyles);
  }
  dynamicStyles.sheet.insertRule(body, dynamicStyles.length);
}
// Inject Keyframes Dynamically

// Home Map
function homeMap() {
  // let countryName;
  let markersCount = 0;
  let arrowIterations = 0;
  $('.home-map-section .marker-item').each(function () {
    markersCount++;
  });

  setTimeout(function() {
    arrowControl(markersCount, arrowIterations);
  }, 10000);

  setInterval(function(){
    arrowIterations++;
    arrowControl(markersCount, arrowIterations);
  }, 5000);
}
// Home Map

// Home Map Arrow Control
function arrowControl(markersCount, arrowIterations) {
  const numbers = Array(markersCount).fill().map((_, index) => index + 1);
  numbers.sort(() => Math.random() - 0.5);

  let firstNum = numbers[0];
  let secondNum = numbers[1];

  let containerWidth = $('#arrow-wrp').width();
  let containerHeight = $('#arrow-wrp').height();

  let startX = $(`.marker-item.marker-${firstNum}`).data('xcoordinate');
  let startY = $(`.marker-item.marker-${firstNum}`).data('ycoordinate');
  let endX = $(`.marker-item.marker-${secondNum}`).data('xcoordinate');
  let endY = $(`.marker-item.marker-${secondNum}`).data('ycoordinate');

  let directLength = Math.sqrt((startX-endX)**2 + (startY-endY)**2);

  let arrowWidth = containerWidth*directLength/50;
  let arrowHeight = arrowWidth/5;
  let arrowLeftSpace = containerWidth*startX/50;
  let arrowTopSpace = containerHeight*startY/27 - arrowHeight;

  let alphaDegrees;
  if (startX-endX == 0 && startY-endY>0) {
    alphaDegrees = 270;
  } else if (startX-endX == 0 && startY-endY<0) {
    alphaDegrees = 90;
  } else if (endX-startX > 0 && endY-startY >= 0) {
    alphaDegrees = Math.atan((endY-startY) / (endX-startX))*180/Math.PI;
  } else if (endX-startX < 0 && endY-startY >= 0) {
    alphaDegrees = 180 - Math.atan((endY-startY) / (startX-endX))*180/Math.PI;
  } else if (endX-startX < 0 && endY-startY < 0) {
    alphaDegrees = 180 + Math.atan((startY-endY) / (startX-endX))*180/Math.PI;
  } else if (endX-startX > 0 && endY-startY < 0) {
    alphaDegrees = 360 - Math.atan((startY-endY) / (endX-startX))*180/Math.PI;
  }

  var path = document.querySelector('.path');
  var svgLength = path.getTotalLength()*arrowWidth/500;

  $('#arrow-image').css('opacity', '1');
  $('#arrow-image').css('width', `${arrowWidth}px`);
  $('#arrow-image').css('height', `${arrowHeight}px`);
  $('#arrow-image').css('left', `${arrowLeftSpace}px`);
  $('#arrow-image').css('top', `${arrowTopSpace}px`);
  $('#arrow-image').css('transform', `rotate(${alphaDegrees}deg)`);
  $('#arrow-image').css('transform-origin', `0 ${arrowHeight}px`);
  $('#line-path').css('stroke-dasharray', svgLength);
  $('#line-path').css('stroke-dashoffset', svgLength);
  $('#line-path').css('animation', `dash${arrowIterations} 1.5s ease-out forwards`);

  addAnimation(`
    @keyframes dash${arrowIterations} {
      to {
        stroke-dashoffset: 0;
      }
    }
  `);
}
// Home Map Arrow Control

function scrollOnID() {
  $('a').on('click', function() {

    // Make sure this.hash has a value before overriding default behavior
    if (this.hash !== '') {
      // Prevent default anchor click behavior
      // event.preventDefault();

      // Store hash
       var hash = this.hash;

      // Using jQuery's animate() method to add smooth page scroll
      // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
      $('html, body').animate({
        scrollTop: $(hash).offset().top,
      }, 100, function(){

        // Add hash (#) to URL when done scrolling (default click behavior)
        window.location.hash = hash;
      });
    } // End if
  });
}
